<template>
    <v-form ref="form" :disabled="loading">
        <v-row>
            <v-col>
                <span class="font-weight-bold dark--text"> Status </span>
                <v-select
                    dense
                    v-model="nftoken.status"
                    :items="statuses"
                    item-text="title"
                    item-value="value"
                    outlined
                    color="black"
                    @change="handleEdit"
                ></v-select>
            </v-col>
            <v-col>
                <span class="font-weight-bold dark--text"> Type </span>
                <v-select
                    dense
                    v-model="nftoken.type"
                    :items="types"
                    item-text="title"
                    item-value="value"
                    outlined
                    color="black"
                    @change="handleEdit"
                ></v-select>
            </v-col>
        </v-row>
        <CustomInput
            label="Title*"
            v-model="nftoken.title"
            :rules="[(v) => !!v || 'Title is required']"
            required
            :error="errors.title"
            @change="handleEdit"
        />

        <CustomInput
            label="Description*"
            type="textarea"
            v-model="nftoken.description"
            :rules="[(v) => !!v || 'Description is required']"
            required
            :error="errors.description"
            @change="handleEdit"
        />

        <div class="d-flex flex-row align-center">
            <span class="font-weight-bold dark--text mr-2">
                Is it on sale already?
            </span>
            <v-radio-group
                v-model="nftoken.is_sale_on"
                row
                :error-messages="errors.is_sale_on"
                @change="handleEdit"
            >
                <v-radio color="secondary" label="Yes" :value="true"></v-radio>
                <v-radio color="secondary" label="No" :value="false"></v-radio>
            </v-radio-group>
        </div>
        <v-row>
            <v-col>
                <CustomDateInput
                    label="Date of sale"
                    v-model="nftoken.sale_date"
                    :error="errors.is_sale_on"
                    @change="handleEdit"
                />
            </v-col>
            <v-col>
                <PriceInput
                    v-model="nftoken.sale_price"
                    label="Sale price*"
                    @setCurrency="(v) => (nftoken.sale_currency = v)"
                    :currency="nftoken.sale_currency"
                    :rules="[(v) => !!v || 'Price is required']"
                    required
                    :error="errors.sale_price"
                    disable
                    @change="handleEdit"
                />
            </v-col>
        </v-row>
        <v-row class="secondary--text text-body-2">
            <v-col v-for="(image, i) in nftoken.images" :key="i">
                <CustomFileUpload
                    :label="
                        image.type == 'big' ? 'Large image' : 'Small image ' + i
                    "
                    v-model="nftoken.images[i]"
                    :error="nftoken.images[i].error"
                    @change="handleEdit"
                />
            </v-col>
        </v-row>
        <div>
            <CustomInput
                label="Total supply*"
                type="number"
                v-model="nftoken.total_supply"
                :rules="[(v) => !!v || 'Total supply is required']"
                required
                :error="errors.total_supply"
                @change="handleEdit"
            />
        </div>
        <v-row>
            <v-col>
                <div>
                    <CustomInput
                        label="Website*"
                        v-model="nftoken.website"
                        :rules="[(v) => !!v || 'Website is required']"
                        required
                        :error="errors.website"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
            <v-col>
                <div>
                    <CustomInput
                        label="Button url"
                        v-model="nftoken.button_url"
                        :error="errors.button_url"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div>
                    <CustomInput
                        label="Twitter"
                        v-model="nftoken.twitter"
                        :error="errors.twitter"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
            <v-col>
                <div>
                    <CustomInput
                        label="Opensea"
                        v-model="nftoken.opensea"
                        :error="errors.opensea"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div>
                    <CustomInput
                        label="Discord"
                        v-model="nftoken.discord"
                        :error="errors.discord"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
            <v-col>
                <div>
                    <CustomInput
                        label="Email"
                        type="email"
                        v-model="nftoken.email"
                        :error="errors.email"
                        @change="handleEdit"
                    />
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "NftokenEditForm",
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            nftoken: "nftoken/item",
            errors: "nftoken/errors",
        }),
    },
    methods: {
        ...mapMutations({
            setErrors: "nftoken/setErrors",
        }),
        handleEdit() {
            if (this.$refs.form.validate()) {
                this.setErrors({});
                this.loading = true;
                this.$emit("change", () => {
                    this.loading = false;
                });
            }
        },
    },
    created() {
        this.statuses = [
            { title: "Submitted", value: "submitted" },
            { title: "Validated", value: "is_valid" },
            { title: "Activated", value: "is_active" },
        ];
        this.types = [
            { title: "Live", value: "live" },
            { title: "Mint", value: "mint" },
        ];
    },
};
</script>

<style lang="scss" scoped>
</style>
